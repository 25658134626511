import IndexPage from "./index"
import React from "react"

//<site>/invite should redirect to the homepage
//We use firebase dynamic link: https://ForkMore.page.link/invite
//And when desktop clicks it will go to: https://ForkMore.com/invite
//The app knows how to respond to this URL, so now desktop will too - go to homepage
const InvitePage = () => (
  <IndexPage/>
)

export default InvitePage
